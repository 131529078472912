<template>
  <v-row>
    <v-col cols="12" md="3">
      <h5 class="green400--text text-body-2" v-text="'Rank'" />
      <p class="mb-0 text--secondary lh-1-2 text-caption" v-text="rank" />
    </v-col>
    <v-col cols="12" md="3">
      <h5 class="green400--text text-body-2" v-text="'Leaderboard'" />
      <p v-t="type" class="mb-0 text--secondary lh-1-2 text-caption" />
    </v-col>
  </v-row>
</template>

<script>

const TYPES = {
  day: 'common.today',
  week: 'common.week',
  month: 'common.month',
}

export default {
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  computed: {
    rank() {
      if (this.transaction.data?.rank === undefined) return null

      return +this.transaction.data.rank + 1
    },
    type() {
      return TYPES[this.transaction.data?.type]
    },
  },
}
</script>
